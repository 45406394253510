*, :after, :before {
  -webkit-box-sizing:border-box;
  -moz-box-sizing:border-box;
  box-sizing:border-box
}
html {
  -moz-tab-size:4;
  tab-size:4;
  line-height:1.15;
  -webkit-text-size-adjust:100%
}
body {
  margin:0;
  font-family:system-ui, -apple-system, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji
}
hr {
  height:0;
  color:inherit
}
abbr[title] {
  -moz-text-decoration:underline dotted;
  text-decoration:underline dotted
}
b, strong {
  font-weight:bolder
}
code, kbd, pre, samp {
  font-family:ui-monospace, SFMono-Regular, Consolas, Liberation Mono, Menlo, monospace;
  font-size:1em
}
small {
  font-size:80%
}
sub, sup {
  font-size:75%;
  line-height:0;
  position:relative;
  vertical-align:baseline
}
sub {
  bottom:-.25em
}
sup {
  top:-.5em
}
table {
  text-indent:0;
  border-color:inherit
}
button, input, optgroup, select, textarea {
  font-family:inherit;
  font-size:100%;
  line-height:1.15;
  margin:0
}
button, select {
  text-transform:none
}
[type=button], [type=reset], [type=submit], button {
  -webkit-appearance:button
}

h1, h2, h3, h4, h5, h6, hr, p {
  padding:0;
  margin:0
}
button, input, optgroup, select, textarea {
  font:inherit;
  color:inherit;
  background:none
}

th {
  text-align:left
}
body {
  font-size:14px;
  line-height:20px
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #e0e7ff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


a {
  text-decoration:none;
  scrollbar-width:8px;
  color:#818cf8;
}

a:hover{
  color:#818cf8;
  text-decoration: underline;
}


::-webkit-scrollbar {
  width: 16px;
  height: 16px;
}

::-webkit-scrollbar-thumb {
  background-color: #21252b;
}

::-webkit-scrollbar-thumb {
  background-color: #474e5c;
}


::-webkit-scrollbar-corner, ::-webkit-scrollbar-track {
  background-color: #21252b;
}

.table-hover>tbody>tr:hover>*{
  color: #e0e7ff;
}

.popover{
  background: #2a273f;
  border-color: #59546d;
}

.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::after, .bs-popover-bottom>.popover-arrow::after{
  border-bottom-color: #2a273f;
}

.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::before, .bs-popover-bottom>.popover-arrow::before{
  border-bottom-color: #59546d;
}

.popover-body{
  padding: 8px;
}


input, button{
  color: rgb(222, 221, 244);
}

.popover{
  max-width: fit-content;
}

button:focus:not(:focus-visible){
  box-shadow: none;
}

.input-module_input{
  color: #fff;
}

.default_input.form-control.input_readOnly[readonly] {
  background-color: #e9ecef;
  opacity: 1;
  color: #000;
}

.table_table .table{
  min-width: 100%;
}

/* 首列固定 */
.table_table thead tr > th:first-child,
.table_table tbody tr > td:first-child {
  position: sticky;
  left: 0;
  z-index: 1;
  background-color: #2a273f;;
}

/* 表头固定 */
.table_table thead tr > th {
  position: sticky;
  top: 0;
  z-index: 2;
  background: #232136;
}

/* 表头首列强制最顶层 */
.table_table thead tr > th:first-child {
  z-index: 3;
}

.table_table .table thead{
  background: #232136;
}