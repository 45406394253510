
.header_container {
    overflow: auto;
}

.header_container .content{
    min-width: 1100px;
    display: -moz-box;
    display: flex;
    -moz-box-align: end;
    align-items: flex-end;
    grid-gap: 16px;
    gap: 16px;
    padding: 0 16px;
}

.header_title{
    font-size: 24px;
    line-height: 32px;
}

.text-module_highlightStrong {
    color: #818cf8;
}

.label_label{
    display: block;
    margin-bottom: 4px;
    font-size: 11px;
    letter-spacing: .5px;
    line-height: 11px;
    font-weight: 600;
    text-transform: uppercase;
}

.button_group{
    display: flex;
}


.button-module_button{
    background-color:#393749;
    /* box-shadow: 0px 0.5px 2px,inset 0px 1px 0px rgba(0,0,0,0.5); */
    height: 32px;
    padding: 0 12px;
    width: -moz-fit-content;
    width: fit-content;
    border: 1px solid transparent;
    outline: 8px solid transparent;
    outline-offset: -2px;
    display: flex;
    align-items: center;
    line-height: 1;
    border-color: #000;
    justify-content: center;
}

.button-module_button.active {
    background-color: #1a1825;
    -webkit-box-shadow: none;
    box-shadow: none;
}



.none-module_outset, .highlight-module_outset{
    /* box-shadow: 0px 0.5px 2px,inset 0px 1px 0px rgba(0,0,0,0.5); */
    border-color: #000;
}

.highlight-module_outset{
    color: #fff;
    background-color: #6366f1;
    font-weight: 500;
}



.input-module_input {
    background-color: #1a1825;
    -webkit-box-shadow: 0px 0.5px 1.5px inset rgba(0,0,0,.3);
    box-shadow: 0px 0.5px 1.5px inset rgba(0,0,0,.3);
    border-color: #000;
    border-width: 1px;
    border-style: solid;
    padding-right: 12px;
    padding-left: 12px;
    height: 32px;
    outline-offset: -2px;
}

.input-module_input:focus-visible {
    outline: solid 2px #6366f1;
}

.div-module_grow{
    display: block;
    -moz-box-flex: 1;
    flex: 1 0;
}

.group-tail .outset-module_main {
    border-left: none;
}

.default_btn.btn-check:focus+.btn-outline-secondary, .default_btn.btn-outline-secondary:focus{
    box-shadow: none;
}

.bg_green,.bg_green:hover{
    background-color: #10b981;
    color: white;
}

.addProject_model .modal-content{
    background-color:rgb(42, 39, 63);
    border-width: 1px;
    border-style: solid;
    border-color: #59546d;
    border-radius: 0;
}

.addProject_model .modal-header{
    border-bottom: none;
}

.addProject_model .modal-header .btn-close{
    background-image: url(../../../img/close.svg);
}

.default_input.form-control:focus{
    color: inherit;
    background-color: #1a1825;
    border-color: #000;
    outline: 0;
    box-shadow: 0px 0.5px 1.5px inset rgb(0 0 0 / 30%);
}

.default_select{
    line-height: 1.6;
    color: #deddf4;
    height: inherit;
    font-size: 13px;
}