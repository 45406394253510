.div-module_grow{
    display: block;
    -moz-box-flex: 1;
    flex: 1 0;
}

.highlight-module_outset{
    background-color: #6366f1;
}


.header_value{
    height: 32px;
    line-height: 32px;
    font-weight: 600;
    font-size: 16px;
}

.colors-module_green {
    background-color: #065f46;
}


.select-module_mediumSelect {
    height: 32px;
    padding-left: 12px;
    padding-right: 32px;
    background-color: #393749;
}

.select-module_select {
    width:100%;
    text-align:left;
    cursor:pointer;
    -webkit-appearance:none;
    -moz-appearance:none;
    appearance:none;
    position: relative;
}
.select-module_select:disabled {
    cursor:auto;
    pointer-events:none
}
select.select-module_select:not([multiple]):not([size]) {
    background-image:none
}


.outset-module_main {
    /* -webkit-transition:background-color .1s, outline .2s ease-out, -webkit-box-shadow .1s; */
    /* transition:background-color .1s, outline .2s ease-out, -webkit-box-shadow .1s; */
    /* transition:background-color .1s, box-shadow .1s, outline .2s ease-out; */
    /* transition:background-color .1s, box-shadow .1s, outline .2s ease-out, -webkit-box-shadow .1s; */
    border:1px solid transparent;
    --shadow-size:0px 0.5px 2px;
    --inset-shadow:inset 0px 1px 0px
}

.select_content{
    position: relative;
}

.select_content:after{
    content: '';
    color: red;
    display: block;
    position: absolute;
    width: 12px;
    height: 12px;
    background-image: url('../../img/arrow.svg');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    right: 14px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none; 
}

.select-module_select:focus-visible {
    outline: solid 2px #6366f1;
}

.select-module_select:active {
    background-color: #2a273f;
    -webkit-box-shadow: none;
    box-shadow: none;
}


.outline-module_always, .outline-module_normal {
    /* -webkit-transition: outline .2s ease-out; */
    /* transition: outline .2s ease-out; */
    outline: 8px solid transparent;
    outline-offset: -2px;
}

.button-module_button:disabled{
    color: #59546d;
    cursor: not-allowed;
}

.loading_icon{
    width: 30px;
}

.loading_icon .spinner-border{
    width: 24px;
    height: 24px;
    vertical-align: initial;
}