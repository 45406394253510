.notification_box .notification{
  width: 384px;
  max-width: calc(100vw - 24px * 2);
  padding: 16px 24px;
  overflow: hidden;
  color: #e0e7ff;
  font-size: 14px;
  position: fixed;
  right:20px;
  line-height: 1.5715;
  word-wrap: break-word;
  background-color: rgb(42, 39, 63);
  border: 1px solid #59546d;
  border-radius: 0.3rem;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  z-index: 9999;
}

.notification_box .close{
  position: absolute;
  top: 14px;
  right: 24px;
  line-height: 1;
  font-size: 14px;
  cursor: pointer;
  /* color:rgba(0, 0, 0, 0.45); */
  color: #e0e7ff;
}
.notification_box .icon-box{
    padding-right: 10px;
    padding-top: 5px;
}
.notification_box .content{
  margin: 0;
  font-size: 14px;
  /* color:rgba(0, 0, 0, 0.85); */
  color: #e0e7ff;
  overflow-wrap: break-word;
  text-size-adjust: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}

.notification_box .fade-enter, .fade-appear {
  opacity: 0;
  right: -2000px;
}
.notification_box .fade-enter-active, .fade-appear-active {
  opacity: 1;
  right: 20px;
  transition: all .35s linear;
}
.notification_box .fade-enter-done {
  opacity: 1;
}

.notification_box .fade-exit {
  opacity: 1;
}

.notification_box .fade-exit-active {
  opacity: 0;
  transition: all .35s ease-in;
}
.notification_box .fade-exit-done {
  opacity: 0;
}

.flexBox{
  display: flex;
  align-content: center;
}