.rank_panel {
  display: flex;
  flex-direction: column;
  grid-gap: 16px;
  gap: 16px;
  width: 200px;
}

.rank_body, .rank_footer {
  display: -moz-box;
  display: flex;
  grid-gap: 8px;
  gap: 8px;
}

.input-module_input{
  width: 100%;
}

.button-module_button.button-module_fill{
  width: 100%;
}

.button-group-module_container{
  display: flex;
}

.button-group-module_childFill{
  flex: 1 1;
}

.price_filter {
  display: -moz-box;
  display: flex;
  grid-gap: 16px;
  gap: 16px;
  margin-top: 16px;
  padding-top: 16px;
  border-top-style: solid;
  border-top-width: 1px;
}


.price_form {
  grid-gap: 16px;
  gap: 16px;
  width: 200px;
  display: -moz-box;
  display: flex;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  flex-direction: column;
}


.price_body, .price_footer {
  display: -moz-box;
  display: flex;
  grid-gap: 8px;
  gap: 8px;
}

.price_body {
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  flex-direction: column;
}

.price_shortcuts {
  border-left-style: solid;
  border-left-width: 1px;
  padding-left: 16px;
  grid-gap: 8px;
  gap: 8px;
  width: 160px;
  display: -moz-box;
  display: flex;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  flex-direction: column;
}

.detail_model{
  max-width: max-content;
}

.detail_container{
  position: relative;
  display: grid;
  grid-gap: 32px;
  gap: 32px;
  padding: 10px 32px 32px;
  max-height: 80vh;
  overflow-y: auto;
  grid-template-columns: 300px 300px 450px;
  grid-template-rows: 100%;
  overflow: hidden;
  max-height: 100vh;
}

.detail_model .modal-body{
  background-color:rgb(42, 39, 63);
  border-width: 1px;
  border-style: solid;
  border-color: #59546d;
  border-top: none;
  border-radius: 0;
  padding: 0;
}

.detail_model .modal-header{
  border-width: 1px;
  border-style: solid;
  border-color: #59546d;
  border-bottom: none;
  border-radius: 0;
  background-color:rgb(42, 39, 63);
  padding-bottom: 0;
  padding-top: 6px;
}


.detail_model .modal-header .btn-close{
  background-image: url(../../img/close.svg);
}

.detail_image img {
  max-width: 100%;
  height: auto;
}

.table_name{
  cursor: pointer;
  color: #818cf8;
}

.table_name:hover{
  text-decoration: underline;
}

.detail_info {
  font-size: 18px;
  line-height: 27px;
  display: -moz-box;
  display: flex;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  flex-direction: column;
  grid-gap: 16px;
  gap: 16px;
}

.detail_name {
  font-size: 24px;
  line-height: 36px;
  font-weight: 700;
}

.detail_name button {
  font-size: 14px;
  display: inline-block;
  margin-left: 8px;
}

.button-module_button.size-module_large{
  font-size: 16px;
  height: 40px;
  color: #fff;
}

.text-module_strong {
  font-weight: 600;
}

.table_space.table_table th:first-child,.table_space.table_table td:first-child {
  border-right-width: 0px;
  border-right-style: solid;
}


.table_name{
  margin: -8px 0px;
}

.table_buy{
  margin: -12px 0;
}

.list_table{
  flex: 1;
  overflow: hidden;
}

.list_event{
  flex: 0 0 auto;
  width: 360px;
}

.feed_container{
  height: 100%;
  display: flex;
  flex-direction: column;
}

.header_header{
  padding: 8px 20px;
}

.feed_body, .all_body{
  position: relative;
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow: hidden;
}

.body_body{
  flex: 1 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.body_title{
  flex: 0 0 auto;
}

.body_list{
  flex: 1 1;
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.body_item{
  padding: 8px;
  border: 1px solid #3f3c53;
}

.item_container {
  display: flex;
  grid-gap: 8px;
  gap: 8px;
}

.item_image{
  flex: 0 0 auto;
  display: block;
  border-radius: 2px;
  cursor: pointer!important;
}

.item_body {
  flex: 1 1;
}

.item_content {
  display: flex;
  flex-direction: column;
}

.item_sub {
  font-size: 13px;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.sale_tag{
  background-color: #065f46;
  color: #d1fae5;
}

.listing_tag{
  background-color: #3730a3;
  color: #e0e7ff;
}

.body_title{
  padding: 8px;
  font-size: 20px;
  line-height: 1;
  text-transform: uppercase;
}

.item_tag span{
  height: 20px;
}

.item_container .a{
  cursor: pointer;
  color: #818cf8;
}

.all_body{
  padding: 20px;
}

.all_title{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.all_title b{
  display: inline-block;
  width: 8px;
  height: 8px;
  background-color: rgba(255,193,7,1);
}

.all_title .sale_color{
  background-color:  rgba(13,202,240,1);
}

.all_body_item{
  padding: 10px 0;
}

.all_body_item .progress{
  height: 1.4rem;
  border-radius: 0;
  background-color:  rgba(13,202,240,1);
}

.all_body_item .progress-bar{
  color: #000;
}

.all_body_item .progress .progress-bar:first-child{
  text-align: left;
  text-indent: 12px;
  z-index: 1;
}

.all_body_item .progress .progress-bar:last-child{
  text-align: right;
  margin-left: -12px;
}

.all_body_body{
  flex: 1 1;
  overflow: auto;
  display: flex;
  flex-direction: column;
}