.App{
  height: 100vh;
  display: -moz-box;
  display: flex;
  background-color: #232136;
}

.icon-home{
  color: #ffff;
  width:24px;
  height:24px
}

.slide_nav{
  flex: 0 0 auto;
  padding: 10px;
  border-right: 1px solid #59546d;
}

.main {
  flex: 1 1;
  overflow: hidden;
  flex-direction: column;
  display: flex;
}

.page_body{
  flex: 1 1;
  overflow: hidden;
}

.list_container{
  display: flex;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  flex-direction: column;
  height: 100%;
  grid-gap: 16px;
  gap: 16px;
  padding: 16px;
}

.list_body{
  flex: 1 1;
  overflow: hidden;
  display: flex;
  grid-gap: 16px;
  gap: 16px;
}

.table-module_container {
  border-collapse: separate;
  border-spacing: 0;
  background-color: #2a273f;
  color: #deddf4;
}






.pane-module_fullHeight {
  height: 100%;
  width: 100%;
  box-shadow: 0px 1px 4px rgba(0,0,0,0.1);
  border-color: #59546d;
  border-width: 1px;
  border-style: solid;
  background-color: #2a273f;
  color: #deddf4;
}

.table_table {
  white-space: nowrap;
  overflow: auto;
  height: 100%;
}

.table_table .table{
  margin: 0;
}

.table_table th, .table_table td{
  border-bottom-style: solid;
  border-bottom-width: 1px;
  padding: 8px 8px 8px 16px;
  border-color: #59546d!important;
  vertical-align: middle;
}
.table_table  thead th{
  padding: 8px;
  padding-left: 16px;
}
.table_table th:first-child,.table_table td:first-child{
  border-right-width: 1px;
  border-right-style: solid;
}


.table_projectImage {
  cursor: pointer;
  vertical-align: middle;
  margin-right: 12px;
}


.table_status {
  text-transform: capitalize;
}


.tag-module_container {
  display: -moz-inline-box;
  display: inline-flex;
  -moz-box-align: center;
  align-items: center;
  padding: 0 8px;
  font-weight: 500;
  height: 24px;
  margin: -4px 0;
  /* background-color: #3730a3; */
  color: #e0e7ff;
  cursor: pointer;
}


.table_explorer {
  display: -moz-box;
  display: flex;
  grid-gap: 16px;
  gap: 16px;
}

.link_container{
  grid-gap: 4px;
  gap: 4px;
  color: #818cf8;
  display: flex;
  align-items: center;
}




/* // page_footer */

.info_container{
  padding: 0 16px 8px;
  margin-top: -8px;
  display: -moz-box;
  display: flex;
  grid-gap: 24px;
  gap: 24px;
}

.text-module_muted {
  color: #6e6986;
}

.info_prices {
  display: -moz-inline-box;
  display: inline-flex;
  grid-gap: 16px;
  gap: 16px;
}


.colors-module_red {
  /* --category-1:#fee2e2;
  --category-5:#ef4444; */
  background-color:#991b1b
}
.colors-module_yellow{
  /* --category-1:#fef3c7;
  --category-5:#f59e0b; */
  background-color:#92400e
}
.colors-module_green {
  /* --category-1:#d1fae5;
  --category-5:#10b981; */
  background-color:#065f46
}

.colors-module_indigo{
  /* --category-1:#e0e7ff;
    --category-5:#6366f1;
    --category-8:#3730a3 */
  background-color: #3730a3;
}


.login_container label>span {
  color: #10b981;
}